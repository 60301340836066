import Badge from "./Badge";
import HerJett from "../../../../svgs/HerJett";

const HerJettBadge = () => {
  return (
    <Badge label="Her Jett" color={"#transparent"}>
      <HerJett size={58} />
    </Badge>
  );
};

export default HerJettBadge;
