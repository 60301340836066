const CaughtEmAll = (props) => {
  const { size } = props;

  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 860 625"
      width={size}
      height={size}
    >
      <defs>
        <image
          width="544"
          height="544"
          id="caught"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAiAAAAIgBAMAAABOp88vAAAAAXNSR0IB2cksfwAAABVQTFRF9/f3AAAA/wAAhwAA92q7////q6mqeYLdyQAAAAd0Uk5TAP///////6V/pvsAAAO3SURBVHic7dDBbdwwFEXRaSEtpIW04BbcQvovwUsHFwg+CFGa0fjcrSCS7zweJ/drc2e/9/SAJCAJSAKSgCQgCUgCkoAkIAlIApKAJCAJSAKSgCQgCUgCkoAkIAlIApKAJCAJSAKSgCQg6ceBTAN+b+7lwYAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQG4Ocvbg1f6ky4GAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIEBuDjIBfAy9HRAQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAAECBMjNQVYBPoeuBirIYSAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQF4YZAKY6uCrAdoEMgIBAQIECJDvgCQgCUgCkoAkIAlIApKAJCAJSAKSgCQgCUgCkoAkIAlIApKAJCAJSAKSgCQg6ekgz24VAAgQIED+DUgCkoAkIAlIApKAJCAJSAKSgCQgCUgCkoAkIAlIApKAJCAJSAKSgCQgCUgCkoCk00FeHWg3ABAgQIAAAfIdkAQkAUlAEpAEJAFJQBKQBCQBSUASkAQkAUlAEpAEJAFJQBKQBCQBSUASkPR0kKNAu88HAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABci7Ian/TZ+r3/r97cHu0PbP/H5AEJAFJQBKQBCQBSUASkAQkAUlAEpAEJAFJQBKQBCQBSUASkAQkAUlAEpAEJAFJQBKQBCTdDmQC6oDVC/v/1Or5030TOJAEJAFJQBKQBCQBSUASkAQkAUlAEpAEJAFJQBKQBCQBSUASkAQkAUlAEpAEJAFJQBKQBCQBSYdBVgf1QasPBgIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAbIXZAKaBk5Aq4NW2w4ABAgQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAAHy5iC7gXYPvhwACBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQLkzUEmoAnsaNN9lwMAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIC8OcjUNGC1Z+85HJAEJAFJQBKQBCQBSUASkAQkAUlAEpAEJAFJQBKQBCQBSUASkAQkAUlAEpAEJAFJQBKQBCTdDeQLqXMQSOmv6HUAAAAASUVORK5CYII="
        />
      </defs>
      <style></style>
      <use id="caught" href="#caught" x="158" y="41" />
    </svg>
  );
};

export default CaughtEmAll;
